<template>
  <div class="flex justify-center items-center">
    <SwdButton primary small class="mr-2" @click="navigateTasks">
      <el-badge class="item" type="primary">
        <InlineSvg :src="IconTasks" class="cursor-pointer" />
      </el-badge>
    </SwdButton>
  </div>
</template>

<script>
import IconTasks from '@/assets/svg/icon-task.svg'
import { useRouter } from 'vue-router'
export default {
  name: 'Tasks',
  setup() {
    const router = useRouter()
    const navigateTasks = () => {
      router.push({ name: `tasks` })
    }
    return {
      IconTasks,
      navigateTasks,
    }
  },
}
</script>